<template>
  <div>
    <b-modal
      id="modal-update-mock-url"
      ref="modalMockUrl"
      title="Input Mock Website URL"
      ok-title="Submit"
      centered
      cancel-variant="outline-secondary"
      @show="mockUrl = ''"
      @hidden="mockUrl = ''"
      @ok="updateMockUrl"
    >
      <form
        ref="form"
      >
        <b-form-group
          label-for="mock-url"
        >
          <b-form-input
            id="mock-url"
            v-model="mockUrl"
          />
        </b-form-group>
      </form>
    </b-modal>
    <b-modal
      id="modal-update-mock-app-name"
      ref="modalMockAppName"
      title="Input Mock APP Name"
      ok-title="Submit"
      centered
      cancel-variant="outline-secondary"
      @show="mockAppName = ''"
      @hidden="mockAppName = ''"
      @ok="updateMockAppName"
    >
      <form
        ref="form"
      >
        <b-form-group
          label-for="mock-app-name"
        >
          <b-form-input
            id="mock-app-name"
            v-model="mockAppName"
          />
        </b-form-group>
      </form>
    </b-modal>

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-button
              variant="outline-primary"
              @click="getApps"
            >
              <feather-icon
                icon="RefreshCwIcon"
                class="mr-50"
              />
              <span class="align-middle">Refresh</span>
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="apps"
        :per-page="perPage"
        :current-page="currentPage"
        primary-key="id"
        show-empty
        striped
        :borderless="false"
        outlined
        empty-text="No matching records found"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >

        <!-- Column: Merchant -->
        <template #cell(merchant)="data">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-primary">
              {{ data.item.merchant_name }}
            </div>
            <small class="text-muted">@{{ data.item.merchant_no }}</small>
          </b-media>
        </template>

        <!-- Column: APP -->
        <template #cell(app)="data">
          <b-media vertical-align="center">
            <div class="font-weight-bold d-block text-primary">
              {{ data.item.app_name }}
            </div>
            <small class="text-muted">@{{ data.item.app_id }}</small>
          </b-media>
        </template>

        <!-- Column: MOCK Website -->
        <template #cell(mock_index_url)="data">
          <a @click="showModal(data.item)">
            {{ data.item.mock_index_url ? data.item.mock_index_url : 'Empty' }}
          </a>
        </template>

        <!-- Column: MOCK APP Name -->
        <template #cell(mock_app_name)="data">
          <a @click="showMockAppNameModal(data.item)">
            {{ data.item.mock_app_name ? data.item.mock_app_name : 'Empty' }}
          </a>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalApps"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0 mr-3"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BFormInput, BButton, BMedia, BPagination, BModal, VBModal, BFormGroup,
} from 'bootstrap-vue'
import { showToast } from '@/libs/tool'
import useAntiFraudJs from './anti-fraud'

const {
  fetchApps,
  updateMockWebsiteUrl,
  updateMockAppName,
} = useAntiFraudJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormInput,
    BButton,
    BMedia,
    BPagination,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      apps: [],
      dataMeta: {
        from: 1,
        to: 1,
        of: 0,
      },
      perPage: 10,
      tableFields: [
        'merchant',
        'app',
        'index_url',
        'mock_index_url',
        'mock_app_name',
      ],
      currentPage: 1,
      totalApps: 0,
      filter: null,
      filterOn: ['app_id', 'app_name', 'merchant_no', 'merchant_name', 'index_url', 'mock_index_url', 'mock_app_name'],
      mockUrl: '',
      mockAppName: '',
      selected: {},
    }
  },
  watch: {
    currentPage() {
      this.recalMetaData()
    },
  },
  methods: {
    recalMetaData() {
      this.dataMeta.from = this.currentPage * 10 - (10 - 1)
      this.dataMeta.to = this.totalApps - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalApps
      this.dataMeta.of = this.totalApps
    },
    getApps() {
      fetchApps(
        apps => {
          this.apps = apps
          this.totalApps = apps.length
          this.recalMetaData()
        },
        fail => {
          showToast(this, 'Warning', `Get APP List with ${fail}.`)
        },
      )
    },
    onFiltered(filteredItems) {
      this.totalApps = filteredItems.length
      this.currentPage = 1
      this.recalMetaData()
    },
    showModal(val) {
      this.selected = val
      this.$refs.modalMockUrl.show()
    },
    showMockAppNameModal(val) {
      this.selected = val
      this.$refs.modalMockAppName.show()
    },
    updateMockUrl() {
      const params = {
        merchant_no: this.selected.merchant_no,
        app_id: this.selected.app_id,
        mock_index_url: this.mockUrl,
      }
      updateMockWebsiteUrl(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Updated fail with ${fail}.`)
        },
      )
    },
    updateMockAppName() {
      const params = {
        merchant_no: this.selected.merchant_no,
        app_id: this.selected.app_id,
        mock_app_name: this.mockAppName,
      }
      updateMockAppName(
        params,
        () => {
          showToast(this, 'Success', 'Updated.')
        },
        fail => {
          showToast(this, 'Warning', `Updated fail with ${fail}.`)
        },
      )
    },
  },
}
</script>
